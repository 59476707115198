<template>
  <div class="class-add">
    <GlobalChunk
      :icon="DetailIMG"
      :title="titleDetail"
      :padding="[32, 0, 95, 55]"
    >
      <el-row>
        <el-col :span="10">
          <GlobalForm
            :init-data="initData"
            :form-rules="formRules"
            :form-item-list="formItemList"
            :labelWidth="130"
            label-position="left"
            @handleConfirm="handleConfirm"
            confirmBtnName="提交"
          >
            <el-form-item class="el_from el_from_hasIos" label="分类图标" slot="operationIcon" :required="true">
              <div class="el_from_content">
                <div class="el_from_content_item">
                    <ElImgUpload
                        key="clickLogo"
                        :exist-image="clickLogo"
                        @handleDelete="handleDelete('clickLogo')"
                        @handleChange="handleChange($event, 'clickLogo')"
                    />
                  <span>选中传白色图标</span>
                </div>
                <div>
                    <ElImgUpload
                        key="logo"
                        :exist-image="logo"
                        @handleDelete="handleDelete('logo')"
                        @handleChange="handleChange($event, 'logo')"
                    />
                  <span>未选中传蓝色图标</span>
                </div>
              </div>
              <span class="tip">各上传1张图片，大小不超过200K，格式为PNG透明底，建议尺寸56*56px</span>
            </el-form-item>
            <el-form-item class="el_from el_from_hasIos" label="是否启用" slot="operationEnable" :required="true">
                <el-switch
                    inactive-color="#bebebe"
                    @change="handleSwitch"
                    v-model="isEnable"
                ></el-switch>
            </el-form-item>
            <el-form-item class="el_from el_from_hasIos" label="是否有苹果系统" slot="operationHasIos" :required="true">
              <el-radio-group
                v-model="initData.isHasIos"
              >
                <el-radio
                  v-for="o in option"
                  :key="o.value"
                  :label="o.value"
                >
                  {{ o.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="el_from el_from_hasIos" label="是否显示规格" slot="operationHasIos" :required="true">
              <el-radio-group
                v-model="initData.showSpec"
              >
                <el-radio
                  v-for="o in option"
                  :key="o.value"
                  :label="o.value"
                >
                  {{ o.label }}
                </el-radio>
              </el-radio-group>
              <p style="color: gray;font-size: 12px;width: 550px;">说明: 选择"是",门店端小程序首页则返回分类对应的"储存空间"、"运行内存"信息选项, 选择"否"则不显示</p>
            </el-form-item>
            <!-- <div slot="default" style="display: flex;align-items: center;width: 1000px;" >
              <div style="width: 5px;height: 17px;border-radius: 5px;background-color: #397ff7;" />
              <div style="font-weight: 700;margin:0 5px 0 3px;">外部分类关联</div> 
              <div style="color:#FF687B">(说明: 关联分类是为了获取外部品牌及机型，请确保关联的准确性和唯一性。若未关联则需要手动添加品牌或机型)</div>
            </div> -->
            <el-form-item class="el_from el_from_hasIos about" label="关联采货侠分类" size="small" slot="operationCai">
              <el-select v-model="chxTypeIds" multiple clearable filterable
                placeholder="请选择" >
                <el-option v-for="item in chxTypeList" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item class="el_from el_from_hasIos about bottom" label="关联分毫分类" size="small" slot="operationFen">
              <el-select v-model="fhTypeIds" multiple clearable filterable
                placeholder="请选择" 
                :disabled="fhFlag"
                >
                <el-option v-for="item in fhTypeList" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
              
            <Provinces slot="provinces" />
            <el-button slot="btnSlot" @click="FromBack">返回</el-button>
          </GlobalForm>
        </el-col>
      </el-row>
    </GlobalChunk>
    <el-dialog
      title="操作提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="530px"
      @closed="handleDialogClosed"
    >
      <div>
        <span style="height:40px;display:flex;justify-content: center;">是否确认添加分类</span>
        <div class="command">
          <div style="width: 85px;">动态口令：</div>
          <el-input
            v-model="command"
            placeholder="请输入谷歌动态口令"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
        <el-button @click.native="dialogVisible=false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElImgUpload from "../../../components/global/components/elements/upload-element";
import Provinces from "@/components/plugins/provinces";
import _api from "@/utils/request";
export default {
  name: "class-add",
  components: { Provinces, ElImgUpload },
  data() {
    return {
      fhFlag:false,
      dialogVisible:false,
      command:'',
      chxTypeIds:[],
      chxTypeList:[],
      fhTypeIds:[],
      fhTypeList:[],
      titleDetail: "添加分类",
      DetailIMG: "add",
      classFormData: [],
      logo:"",
      clickLogo:"",
      isEnable:false,
      initData: {
          type:"",
          machineTypeName:"",
          remark:"",
          isHasIos:"01",
          showSpec:"01"
      },
      i:"",
      option: [
        { label: "是", value: "01" },
        { label: "否", value: "02" },
      ],
      formItemList: [
        {
          key: "type",
          type: "input",
          labelName: "分类编码",
          placeholder: "输入分类编码，最多20位",
          readonly: false, 
          lableWidth: "135px",        
        },
        {
          key: "machineTypeName",
          type: "input",
          labelName: "分类名称",
          placeholder: "输入分类名称（6字内）",
          lableWidth: "135px",
        },
        {
          key: "remark",
          type: "input",
          labelName: "分类备注",
          placeholder: "请输入备注（15字内），该信息将展示在门店小程序端-首页（上传图片处说明）",
          lableWidth: "135px",
        },
        { slotName: "operationIcon" },
        { slotName: "operationEnable" },
        { slotName: "operationHasIos" },
        { slotName: "operationCai" },
        { slotName: "operationFen" },
        // {
        //   key: "isHasIos",
        //   type: "radio",
        //   labelName: "是否有苹果系统",
        //   option: [
        //     { label: "是", value: "01" },
        //     { label: "否", value: "02" },
        //   ],
        // },
      ],

      formRules: {
        type: [
          {
            required: true,
            trigger: "blur",
            message: "输入分类编码，最多20位",
            max: 20,
          },
          {
            pattern:/^[a-zA-Z0-9]{1,20}$/,
            message: '输入分类编码，最多20位', 
            trigger: 'blur'
          }
        ],        
        machineTypeName: [
          {
            required: true,
            trigger: "blur",
            message: "输入分类名称（6字内）",
            max: 6,
          },
        ],
        remark: [
          {
            required: false,
            trigger: "blur",
            message: "请输入备注（15字内）",
            max: 15,
          },
        ],
        isHasIos: [
          {
            required: true,
            trigger: "blur",
            message: "请选择是或者否",
          },
        ],
        showSpec: [
          {
            required: true,
            trigger: "blur",
            message: "请选择是或者否",
          },
        ],
      },
    };
  },
  created(){
    this.getChxSelectList()
    this.getFhSelectList()
  },
  mounted() {
    if (this.$route.query.type === "edit") {
      this.DetailIMG = "edit";
      this.titleDetail = "编辑分类";
      this.formItemList[0].readonly = true
      _api.classInfo({ id: this.$route.query.id }).then((res) => {
        if (res.code === 1) {
          console.log('编辑回显',res.data);
          this.classFormData = res.data;   
          this.initData.type = this.classFormData.type;
          this.initData.machineTypeName = this.classFormData.name;
          this.initData.isHasIos = this.classFormData.isHasIos;
          this.initData.showSpec = this.classFormData.showSpec;
          this.initData.remark = this.classFormData.remark;
          this.clickLogo = this.classFormData.clickLogo;
          this.logo = this.classFormData.logo;
          this.isEnable = this.classFormData.isEnable;
          this.chxTypeIds = this.classFormData['chxList'].map(el => el.id);
          this.fhTypeIds = this.classFormData['fhList'].map(el => el.id);
          this.fhFlag = this.classFormData['fhList'].length > 0 ? true :false
        } else {
          this.$message.error("res.msg");
        }
      });
    } else {
      this.titleDetail = "添加";
      this.DetailIMG = "add";
    }
  },
  methods: {
    handleDialogClosed() {
      this.command = ""
    },
    //弹窗确定
    handleSuccess() {
      this.searchParams.command = this.command
      if (this.$route.query.type === "edit") {
        _api.classAdd(this.searchParams).then((res) => {
          if (res.code === 1) {
            this.$router.push({ path: "/classification/ClassList" });
            this.$message({
              message: res.msg,
              type: "success",
            });
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
      } else {
        _api.classAdd(this.searchParams).then((res) => {
          if (res.code === 1) {
            this.$router.push({ path: "/classification/ClassList" });
            this.$message({
              message: "添加成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "添加失败",
              type: "error",
            });
          }
        });
      }
    },
    // 获取采货侠下拉列表
    getChxSelectList(){
      _api.getChxSelectList({ id: this.$route.query.id }).then((res) => {
        if (res.code === 1) {
          this.chxTypeList = res.data;
        }
      })
    },
    // 获取分毫下拉列表
    getFhSelectList(){
      _api.getFhSelectList().then((res) => {
        if (res.code === 1) {
          this.fhTypeList = res.data;
        }
      })
    },
    // 图标上传回调
    handleChange(e,val) {
        if(val=="logo"){
            this.logo = e
        }else{
            this.clickLogo = e
        }
    },
    handleDelete(val) {
        console.log(val);
    },
    // 是否禁用开关
    handleSwitch(e) {
        this.isEnable = e
    },
    handleConfirm(data, cd) {
      this.dialogVisible = true
      cd();
      console.log(data);
      const classAddOrUpdateDto = {
        type: data.type,
        name: data.machineTypeName,
        logo: this.logo,
        clickLogo: this.clickLogo,
        id: this.classFormData.id?this.classFormData.id:"",
        isEnable: this.isEnable,
        isHasIos: data.isHasIos,
        showSpec: data.showSpec,
        remark: data.remark?data.remark:"",
        chxTypeIds:this.chxTypeIds,
        fhTypeIds:this.fhTypeIds,
      };
      this.searchParams = classAddOrUpdateDto
    },
    FromBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style  scoped lang="scss" ref="stylesheet/scss">
.class-add {
  .el_from {
    margin-left: -80px;
    .el_from_content{
        display: flex;
        .el_from_content_item{
          margin-right: 51px;
        }
        div{
            text-align: center;
            color: #666666;
            /deep/.el-upload{
                background-color: #F5F6FC;
            }
        } 
    }
    .tip{
        color: #FF687B;
    }
  }
  /deep/.el_from_hasIos{
    .el-form-item__label{
      line-height: 20px;
      text-align: left;
      padding-left: 12px;
      position: relative;
    }
    .el-form-item__label::before{
      position: absolute;
      left: 3px;
    }
  }

  /deep/.about .el-input--small .el-input__inner {
      height: 36px;
      width: 365px;
      line-height: 36px;
  } 
  /deep/.el-form-item__label {
              text-align: left;
              vertical-align: middle;
              float: left;
              font-size: 14px;
              color: #666666;
              line-height: 40px;
              padding: 0 0 0 12px;
              box-sizing: border-box;
          }

  .bottom{
    margin-bottom: 20px;
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
</style>