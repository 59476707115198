<template>
  <div class="provinces">
    <el-select v-model="addressInfo.provinceID" :disabled="disabled" placeholder="请选择省" @change="ClickProvince">
      <el-option
        v-for="item in optionsProvince"
        :key="item.cityName"
        :label="item.cityName"
        :value="item.id"
      ></el-option>
    </el-select>
    <el-select v-model="addressInfo.cityID" :disabled="disabled" placeholder="请选择市" @change="ClickCity">
      <el-option
        v-for="item in optionsCity"
        :key="item.cityName"
        :label="item.cityName"
        :value="item.id"
      ></el-option>
    </el-select>
    <el-select v-model="addressInfo.areaID" :disabled="disabled" placeholder="请选择区" @change="ClickArea">
      <el-option
        v-for="item in optionsArea"
        :key="item.cityName"
        :label="item.cityName"
        :value="item.id"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "provinces",
  props: {
    addressInfo: {
      type: Object,
      dafault: {}
    },
    optionsProvince: {
      type: Array,
      required: true
    },
    optionsCity: {
      type: Array,
      required: true
    },
    optionsArea: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      dafault: false
    }
  },
  data() {
    return {
      parentId: "",
      propsCityID: "",
      propsAreaID: ""
    };
  },
  mounted() {
    
  },
  methods: {
    ClickProvince() {
      this.$emit("provinceID", this.addressInfo.provinceID);
      
    },
    ClickCity() {
      this.addressInfo.areaID="",
      this.$emit("cityID", this.addressInfo.cityID);
      
    },
    ClickArea(val) {
      console.log(val);
      this.$emit("areaID", this.addressInfo.areaID);
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.provinces {
  // width: 675px;
  display: flex;
  justify-content: space-between;
}
</style>
